/**
 * 網站介面控制 el Status Store
 *
 */
import { defineStore } from 'pinia';

export interface iRouteState {
    isLoading: boolean // 是否處於Loading狀態
    selectClose: string // 用來記錄 開啟的select ref 點擊外面 直接觸發關閉
}

const useElStatusStore = defineStore('useElStatusStore', () => {
    const elStatusState = ref({
        isLoading: false,
        isEdit: false,
        selectClose: ''
    });

    const startLoading = () => { elStatusState.value.isLoading = true; };
    const endLoading = () => { elStatusState.value.isLoading = false; };

    const startEdit = () => { elStatusState.value.isEdit = true; };
    const endEdit = () => { elStatusState.value.isEdit = false; };

    return {
        elStatusState,
        startLoading,
        endLoading,
        startEdit,
        endEdit
    };
});

export default useElStatusStore;
